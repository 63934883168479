// import React from "react";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// import Informations from "./informations";
// import Excursion from "./excursion";
// import Spa from "./spa";
// import Bar from "./bar";
// import Restauration from "./restauration";
// import Animation from "./animation"; 
// import Splash from "./splash";
// import Menu from "./menu";
// import OpenBar from "./openBar";
// import Penthouse from "./penthouse";
// import Chicha from "./chicha";
// import Island from "./island";
// import Allin from "./allin";
// import Marocain from "./marocain";
// import Fish from "./fish";
// import Cedar from "./cedar";
// import Activities from "./activities";
// import Actv_and_axc from "./actv_and_axc";
// import Animation_pro from "./animation_pro";
// import Kids from "./kids";
// import Looby from "./looby";


// const MyCont = () => {
//   return (
//     <div className="App">
//       <img src="blu.png" alt="Resort Image" width={150} style={{ marginTop: "10px" }} />
      
//       <div className="text">
//       <p>
//         Évadez-vous dans un resort isolé exceptionnel en bord de mer et
//         découvrez le charme unique et l'histoire riche de la côte
//         méditerranéenne du Maroc
//       </p>
//       </div>

//       <div className="title">
//         <h1>
//           Radisson Blu Resort Al Hoceima <p>Every Moment Matters</p>
//         </h1>
//       </div>

//       <div className="content">
//         <div className="buttons">
//           <div className="buttons-left">
//             <Link className="button" to="/looby">Looby</Link>
//             <Link className="button" to="/actv_and_axc">Excursion/Activities</Link>
//             <Link className="button" to="/animation">Animation</Link>
//           </div>
//           <div className="buttons-right">
//             <Link className="button" to="/restauration">Food & Drinks</Link>
//             <Link className="button" to="/spa">Spa Rosemary</Link>
//             <Link className="button" to="/informations">Informations</Link>
//           </div>
//         </div>
//       </div>
//       <footer className="footer">
//         <div className="contact-info">
//           <p>05 39 84 40 00</p>
//           <p>reservation.alhoceima@radissonblu.com</p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// function Home() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<MyCont />} />
//         <Route path="/informations" element={<Informations />} />
//         <Route path="/excursion" element={<Excursion />} />
//         <Route path="/spa" element={<Spa />} />
//         <Route path="/restauration" element={<Restauration />} />
//         <Route path="/animation" element={<Animation />} />
//         <Route path="/bar" element={<Bar />} />
//         <Route path="/splash" element={<Splash />} />
//         <Route path="/menu" element={<Menu />} />
//         <Route path="/openBar" element={<OpenBar />} />
//         <Route path="/penthouse" element={<Penthouse />} />
//         <Route path="/chicha" element={<Chicha />} />
//         <Route path="/island" element={<Island />} />
//         <Route path="/allin" element={<Allin />} />
//         <Route path="/marocain" element={<Marocain />} />
//         <Route path="/fish" element={<Fish />} />
//         <Route path="/cedar" element={<Cedar />} />
//         <Route path="/activities" element={<Activities />} />  
//         <Route path="/actv_and_axc" element={<Actv_and_axc />} />
//         <Route path="/kids" element={<Kids />} />  
//         <Route path="/animation_pro" element={<Animation_pro />} />  
//         <Route path="/looby" element={<Looby />} />  

//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default Home;
// import React, { useMemo } from "react";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// import Informations from "./informations";
// import Excursion from "./excursion";
// import Spa from "./spa";
// import Bar from "./bar";
// import Restauration from "./restauration";
// import Animation from "./animation"; 
// import Splash from "./splash";
// import Menu from "./menu";
// import OpenBar from "./openBar";
// import Penthouse from "./penthouse";
// import Chicha from "./chicha";
// import Island from "./island";
// import Allin from "./allin";
// import Marocain from "./marocain";
// import Fish from "./fish";
// import Cedar from "./cedar";
// import Activities from "./activities";
// import Actv_and_axc from "./actv_and_axc";
// import Animation_pro from "./animation_pro";
// import Kids from "./kids";
// import Looby from "./looby";

// const MyCont = () => {
//   const linksLeft = useMemo(() => (
//     <>
//       <Link className="button" to="/looby">Looby</Link>
//       <Link className="button" to="/actv_and_axc">Excursion/Activities</Link>
//       <Link className="button" to="/animation">Animation</Link>
//     </>
//   ), []);

//   const linksRight = useMemo(() => (
//     <>
//       <Link className="button" to="/restauration">Food & Drinks</Link>
//       <Link className="button" to="/spa">Spa Rosemary</Link>
//       <Link className="button" to="/informations">Informations</Link>
//     </>
//   ), []);

//   return (
//     <div className="App">
//       <img src="blu.png" alt="Resort Image" width={150} style={{ marginTop: "10px" }} />
      
//       <div className="text">
//         <p>
//           Évadez-vous dans un resort isolé exceptionnel en bord de mer et
//           découvrez le charme unique et l'histoire riche de la côte
//           méditerranéenne du Maroc
//         </p>
//       </div>

//       <div className="title">
//         <h1>
//           Radisson Blu Resort Al Hoceima <p>Every Moment Matters</p>
//         </h1>
//       </div>

//       <div className="content">
//         <div className="buttons">
//           <div className="buttons-left">
//             {linksLeft}
//           </div>
//           <div className="buttons-right">
//             {linksRight}
//           </div>
//         </div>
//       </div>
//       <footer className="footer">
//         <div className="contact-info">
//           <p>05 39 84 40 00</p>
//           <p>reservation.alhoceima@radissonblu.com</p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// function Home() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<MyCont />} />
//         <Route path="/informations" element={<Informations />} />
//         <Route path="/excursion" element={<Excursion />} />
//         <Route path="/spa" element={<Spa />} />
//         <Route path="/restauration" element={<Restauration />} />
//         <Route path="/animation" element={<Animation />} />
//         <Route path="/bar" element={<Bar />} />
//         <Route path="/splash" element={<Splash />} />
//         <Route path="/menu" element={<Menu />} />
//         <Route path="/openBar" element={<OpenBar />} />
//         <Route path="/penthouse" element={<Penthouse />} />
//         <Route path="/chicha" element={<Chicha />} />
//         <Route path="/island" element={<Island />} />
//         <Route path="/allin" element={<Allin />} />
//         <Route path="/marocain" element={<Marocain />} />
//         <Route path="/fish" element={<Fish />} />
//         <Route path="/cedar" element={<Cedar />} />
//         <Route path="/activities" element={<Activities />} />  
//         <Route path="/actv_and_axc" element={<Actv_and_axc />} />
//         <Route path="/kids" element={<Kids />} />  
//         <Route path="/animation_pro" element={<Animation_pro />} />  
//         <Route path="/looby" element={<Looby />} />  
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default Home;
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import './App.css'; // Make sure to import your CSS file

const Informations = lazy(() => import("./informations"));
const Excursion = lazy(() => import("./excursion"));
const Spa = lazy(() => import("./spa"));
const Bar = lazy(() => import("./bar"));
const Restauration = lazy(() => import("./restauration"));
const Animation = lazy(() => import("./animation")); 
const Splash = lazy(() => import("./splash"));
const Menu = lazy(() => import("./menu"));
const OpenBar = lazy(() => import("./openBar"));
const Penthouse = lazy(() => import("./penthouse"));
const Chicha = lazy(() => import("./chicha"));
const Island = lazy(() => import("./island"));
const Allin = lazy(() => import("./allin"));
const Marocain = lazy(() => import("./marocain"));
const Fish = lazy(() => import("./fish"));
const Cedar = lazy(() => import("./cedar"));
const Activities = lazy(() => import("./activities"));
const Actv_and_axc = lazy(() => import("./actv_and_axc"));
const Animation_pro = lazy(() => import("./animation_pro"));
const Kids = lazy(() => import("./kids"));
const Looby = lazy(() => import("./looby"));
const Tapas = lazy(() => import("./tapas"));
const Loisir = lazy(() => import("./loisir"));
const Excurcion_double = lazy(() => import("./excurcion_double"));
const Parc = lazy(() => import("./parc"));




const MyCont = () => {
  return (
    <div className="App">
      <img src="blu.png" alt="Resort Image" width={150} style={{ marginTop: "10px" }} />
      
      <div className="text">
        <p>
        Échappez-vous dans un somptueux resort en bord de mer et plongez dans
         le charme distinctif et l'histoire riche de la côte méditerranéenne marocaine.
        </p>
      </div>

      <div className="title">
        <h1>
          Radisson Blu Resort Al Hoceima <p>Every Moment Matters</p>
        </h1>
      </div>

      <div className="content">
        <div className="buttons">
          <div className="buttons-left">
            <Link className="button" to="/looby">Lobby</Link>
            <Link className="button" to="/actv_and_axc">Activities</Link>
            <Link className="button" to="/animation">Animation</Link>
          </div>
          <div className="buttons-right">
            <Link className="button" to="/restauration">Food & Drinks</Link>
            <Link className="button" to="/spa">Spa Rosemary</Link>
            <Link className="button" to="/informations">Informations</Link>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="contact-info">
          <p>05 39 84 40 00</p>
          <p>reservation.alhoceima@radissonblu.com</p>
        </div>
      </footer>
    </div>
  );
};

const routes = [
  { path: "/informations", element: <Informations /> },
  { path: "/excursion", element: <Excursion /> },
  { path: "/spa", element: <Spa /> },
  { path: "/restauration", element: <Restauration /> },
  { path: "/animation", element: <Animation /> },
  { path: "/bar", element: <Bar /> },
  { path: "/splash", element: <Splash /> },
  { path: "/menu", element: <Menu /> },
  { path: "/openBar", element: <OpenBar /> },
  { path: "/penthouse", element: <Penthouse /> },
  { path: "/chicha", element: <Chicha /> },
  { path: "/island", element: <Island /> },
  { path: "/allin", element: <Allin /> },
  { path: "/marocain", element: <Marocain /> },
  { path: "/fish", element: <Fish /> },
  { path: "/cedar", element: <Cedar /> },
  { path: "/activities", element: <Activities /> },
  { path: "/actv_and_axc", element: <Actv_and_axc /> },
  { path: "/kids", element: <Kids /> },
  { path: "/animation_pro", element: <Animation_pro /> },
  { path: "/looby", element: <Looby /> },
  { path: "/tapas", element: <Tapas /> },
  { path: "/loisir", element: <Loisir /> },
  { path: "/excurcion_double", element: <Excurcion_double /> },
  { path: "/parc", element: <Parc /> }


];

function Home() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<MyCont />} />
          {routes.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Home;


